<template>
  <div>
    <!-- basic -->
    <div class="vx-row mb-6 w-full">
      <div class="vx-col md:w-6/12 w-full pt-3">
        <div class="vx-row">
          <div class="vx-col sm:w-1/7">
            <div>
              <vs-switch
                vs-value="1"
                :disabled="
                  item.point != 0 && alertFlage
                    ? true
                    : false || item.name == 'school_director'
                "
                @click="alertRemovePoints"
                v-model="item.status"
              />
            </div>
          </div>
          <div class="vx-col sm:w-6/7">
            <span>{{
              isCustom == true ? $t(`scoreManager.${item.name}`) : item.name
            }}</span>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-3/12 w-full">
        <vs-input-number
          class="score-style"
          readonly
          :disabled="item.status == false || item.name == 'school_director'"
          v-model="item.point"
          min="0"
          :max="item.point + max > 100 ? max : item.point + max"
        />
      </div>
      <div class="vx-col md:w-1/12 sm:12/12 items-center flex">
        <div v-if="isCustom == false">
          <vs-button
            @click="remove"
            size="small"
            color="danger"
            type="filled"
            icon="close"
          ></vs-button>
        </div>
      </div>
    </div>
    <!-- end basic -->
  </div>
</template>

<script>
export default {
  name: 'ScoreManagment',
  props: {
    item: Object,
    isCustom: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: -1
    },
    max: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      alertFlage: false
    }
  },
  methods: {
    remove () {
      if (this.isCustom === false) this.$emit('remove', this.index)
    },
    alertRemovePoints () {
      if (this.item.point != 0 && this.item.status) {
        this.alertFlage = true
        this.item.status = true
        this.errMsg(this.$t('scoreManager.you_should_remove_all_points'))

      } else {
        this.alertFlage = false
      }
    }

    // this.$emit(alertRemovePoints,this.index)
  }
}
</script>

<style scoped>
.score-style {
  background-color: #fff !important;
}
</style>
