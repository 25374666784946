<template>
  <div>
    <div v-if="Object.keys(errorsValidaion).length">
      <template v-for="(item, key, index) in errorsValidaion">
        <vs-alert
          :key="index + key"
          :title="key"
          color="rgb(231, 154, 23)"
          active="true"
        >
          <template v-for="(error, index) in item">
            <div :key="'eee' + index + key">{{ error }}</div>
          </template>
        </vs-alert>
      </template>
    </div>
    <ScoreManagmentForm
      v-if="initLoad == true"
      :Score.sync="Score"
      :is-update="true"
      v-on:edit="onEdit"
      :loading.sync="loading"
    />
  </div>
</template>

<script>
//  npx eslint --fix src/*.ts
import ScoreManagmentForm from '../components/ScoreManagmentForm'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ScoreManager = RepositoryFactory.get('ScoreManager', 'ScoreManager')
export default {
  name: 'Create2',
  components: {
    ScoreManagmentForm
  },
  created () {
    this.init()
  },

  data () {
    return {
      loading: false,
      initLoad: false,
      errorsValidaion: {},
      Score: {
        school_id: '0',
        division_id: '0',
        basic: [
          //start array
          {
            name: 'school_director',
            status: true,
            point: 100
          },
          {
            name: 'division_principal',
            status: true,
            point: 0
          },
          {
            name: 'division_supervisor',
            status: true,
            point: 0
          },
          {
            name: 'division_assessor',
            status: true,
            point: 0
          },
          {
            name: 'other',
            status: true,
            point: 0
          }
        ], // end array
        commun: [
          //start array
          {
            name: 'academic_qualities',
            status: true,
            point: 0
          },
          {
            name: 'candidate_enthusiasm',
            status: true,
            point: 0
          },
          {
            name: 'candidate_sports_skills',
            status: true,
            point: 0
          },
          {
            name: 'educational_background',
            status: true,
            point: 0
          },
          {
            name: 'leadership_skills',
            status: true,
            point: 0
          },
          {
            name: 'parents_culture_fit',
            status: true,
            point: 0
          },
          {
            name: 'distance_from_home',
            status: true,
            point: 0
          }
        ], // end array

        criterias: []
      }
    }
  },
  methods: {
    onEdit (Score) {
      this.loading = true
      this.$vs.loading()
      ScoreManager.edit(this.$route.params.id, Score).then((res) => {
        if (res.key == 1) {
          this.handleSucessUpdate(res)
        } else {
          this.handleErrorUpdate(res)
        }
        this.loading = false
        this.$vs.loading.close()
        this.$router.push({ name: 'scoreManager.list' })

      })
    },
    init () {
      this.$vs.loading()
      ScoreManager.get(this.$route.params.id)
        .then((res) => {
          if ((res.key = 1)) {
            delete res.data['id']
            this.Score = res.data
          }
          this.initLoad = true
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$router.push({ name: 'page-error-404' })
        })
    },
    handleSucessUpdate (res) {
      this.$vs.notify({
        color: 'success',
        title: this.$t('basic.update'),
        text: res.msg
      })
    },
    handleErrorUpdate (res) {
      this.errorsValidaion = res.errors
      const obj = res.errors
      this.$vs.notify({
        color: 'danger',
        title: this.$t('basic.error_validation'),
        text: obj[Object.keys(obj)[0]][0]
      })
    }
  }
}
</script>

<style scoped>
</style>
