<template>
  <div class="vx-row">
    <div
      class="alert-point"
      :style="{ '--main-bg-color': backgorindColrAlert }"
    >
      <h3>{{ $t("scoreManager.total_point") }}</h3>
      <p>{{ 100 - Score.basic[0].point }} / 100</p>
    </div>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-col md:w w-full mb-base">
      <vx-card :title="$t('scoreManager.newScoreSchema')" class="p-6">
        <vs-divider position="center">
          <h5>{{ $t("scoreManager.BasicAssessmentCriteria") }}</h5>
        </vs-divider>
        <!-- basic -->
        <ScoreManagment
          :key="index + 'basic'"
          :max="100 - getSumPointExpectFirst"
          :item.sync="item"
          v-for="(item, index) in Score.basic"
        />
        <!-- end basic -->

        <vs-divider position="center">
          <h5>{{ $t("scoreManager.cutome_criteria") }}</h5>
        </vs-divider>
        <!-- start commu -->
        <div class="mb-3 mt-4"></div>
        <!-- call component -->
        <ScoreManagment
          :key="index + 'sc'"
          :max="100 - getSumPointExpectFirst"
          :item.sync="item"
          v-for="(item, index) in Score.commun"
        />
        <!--  end call -->

        <!-- finsh counu -->

        <!-- custom critieria -->

        <ScoreManagment
          v-on:remove="onRemove"
          :index="index"
          :key="index + 'custom'"
          :is-custom="false"
          :item.sync="item"
          :max="100 - getSumPointExpectFirst"
          v-for="(item, index) in Score.criterias"
        />

        <!-- action to add new  -->
        <div class="mb-3">
          <vs-button
            @click="addNewCritieria"
            color="primary"
            type="line"
            v-if="inserCriteria.isOpen == false"
            >{{ $t("scoreManager.add_criteria_button") }}</vs-button
          >

          <template v-else>
            <vs-row vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="start"
                vs-align="start"
                vs-w="7"
                class="flex items-center"
              >
                <vx-input-group class="w-full">
                  <vs-input
                    class="inputx"
                    @keyup.enter.native="saveInput"
                    :placeholder="$t('scoreManager.place_holder_input_add')"
                    v-model="inserCriteria.name"
                  />

                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button
                        @click="closeInput"
                        color="danger"
                        type="border"
                        icon="close"
                      ></vs-button>
                      <vs-button
                        :disabled="inserCriteria.name == ''"
                        @click="saveInput"
                        color="primary"
                        type="border"
                        icon="done"
                      ></vs-button>
                    </div>
                  </template>
                </vx-input-group>
              </vs-col>
            </vs-row>
          </template>
        </div>

        <!-- end  -->

        <!-- ================= submit ============   -->

        <div class="mt-20">
          <div class="flex w-full justify-between flex-wrap">
            <template v-if="isUpdate == false">
              <vs-button
                color="warning"
                :to="{ name: 'scoreManager.list' }"
                type="border"
                class="mb-2"
                >{{ $t("basic.cancel") }}</vs-button
              >
              <vs-button
                @click="submit"
                :disabled="getSumPoint != 100"
                class="mr-3 mb-2"
                >{{ $t("form.save") }}</vs-button
              >
            </template>
            <template v-else>
              <vs-button
                color="warning"
                :to="{ name: 'scoreManager.list' }"
                type="border"
                class="mb-2"
                >{{ $t("basic.cancel") }}</vs-button
              >
              <vs-button
                @click="submit"
                :disabled="getSumPoint != 100 || loading == true"
                class="mr-3 mb-2"
                >{{ $t("basic.update") }}</vs-button
              >
            </template>
          </div>
        </div>

        <!-- CODE -->
      </vx-card>
    </div>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'

import ScoreManagment from './ScoreManagment'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ScoreManager = RepositoryFactory.get('ScoreManager', 'ScoreManager')

export default {
  name: 'ScoreManagmentForm',
  components: {
    ScoreManagment
  },
  props: {
    Score: Object,
    isUpdate: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      inserCriteria: {
        name: '',
        isOpen: false
      },
      erroMsg: '',
      schools: [],
      divisions: [],
      max: 100
    }
  },
  created () {
    this.initInfo()
  },
  methods: {
    initInfo () {
      ScoreManager.getDropDownData().then((res) => {
        this.schools = res.schools
        this.divisions = res.divisions
      })
    },
    // handle add new
    addNewCritieria () {
      this.inserCriteria.isOpen = true
    },
    closeInput () {
      this.inserCriteria.isOpen = false
      this.inserCriteria.name = ''
    },
    saveInput () {
      if (this.inserCriteria.name) {
        if (
          this.Score.criterias.some(
            (person) => person.name === this.inserCriteria.name.toLowerCase()
          )
        ) {
          this.$vs.notify({
            color: 'danger',
            title: this.$t('basic.alrady_exist'),
            text: obj[Object.keys(obj)[0]][0]
          })
        } else {
          this.Score.criterias.push({
            name: this.inserCriteria.name.toLowerCase(),
            status: false,
            point: 0
          })

          this.closeInput()
        }
      }
    },
    onRemove (index) {
      this.Score.criterias.splice(index, 1)
    },
    // =======
    // method for handle submit
    submit () {
      this.isUpdate === false
        ? this.$emit('store', this.Score)
        : this.$emit('edit', this.Score)
    },
    // helper
    sumPointForArray (array) {
      return array.reduce((total, obj) => {
        return total + obj.point
        return total

        /*if(obj.status == true ){
            return  total + obj.point
          }
          else if(obj.status == false){
            obj.point=0
             return total
          }*/
      }, 0)
    },
    alertPoint () {

      this.$vs.notify({
        title: this.$t('scoreManager.total_point'),
        text: `${this.getSumPoint}`,
        color: 'primary',
        fixed: true
      })
    }
  },

  computed: {
    getSumPoint () {
      const x =
        this.sumPointForArray(this.Score.basic) +
        this.sumPointForArray(this.Score.commun) +
        this.sumPointForArray(this.Score.criterias)
      return x
    },
    getSumPointExpectFirst () {
      const x =
        this.sumPointForArray(this.Score.basic.slice(1)) +
        this.sumPointForArray(this.Score.commun) +
        this.sumPointForArray(this.Score.criterias)
      return x
    },

    backgorindColrAlert () {
      if (this.getSumPoint === 100) return 'rgb(var(--vs-primary))'
      if (this.getSumPoint > 100) return 'rgb(var(--vs-danger))'

      return 'rgb(255, 130, 0)'
    }
  },
  watch: {
    getSumPointExpectFirst (value, old) {
      // alert(old)
      if (old < value) {
        this.Score.basic[0].point = 100 - value
      } else {
        const sub = old - value
        if (this.Score.basic[0].point != 100) this.Score.basic[0].point += sub
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inputx {
  display: block;
  width: 100%;
  background-color: #fff;
}
.background-none {
  background-color: transparent !important;
}
.alert-point {
  position: fixed;
  top: 50%;
  width: 200px;
  background: var(--main-bg-color);
  color: #fff;
  text-align: center;
  z-index: 100000;
  right: 30px;
  padding: 15px;
  border-radius: 5px 0 0 5px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);

  * {
    color: #fff;
  }
  @media (max-width: 770px) {
    & {
      width: 120px;
      h3,
      p {
        font-size: 95%;
      }
    }
  }
  @media (max-width: 440px) {
    & {
      padding: 9px;
      width: 107px;
      right: 17px;
      h3,
      p {
        font-size: 95%;
      }
    }
  }
}
</style>
